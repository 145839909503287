import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import loadable from "@loadable/component";
import { useSetRecoilState } from "recoil";
import { graphql, navigate } from "gatsby";
import { IsMobileDevice } from "../utils/functions";
import CampaignsContent from "../components/campaigns-content";
import BocadoModal from "../components/bocado-modal";
import BocadoCart from "../components/bocado-cart";
import Footer from "../components/footer.jsx";
import Seo from "../components/seo";
import {
    isMobileAtom,
    showNewsletterModalAtom,
    showCartDrawerAtom,
} from "../recoil/atoms.js";
import styles from "../styles/campaigns.module.scss";
import Cookies from "js-cookie";

const Content = ({ data }) => {
    const [isMobile, setIsMobile] = useRecoilState(isMobileAtom);

    const [showNewsletterModal, setShowNewsletterModal] = useRecoilState(
        showNewsletterModalAtom
    );
    const [showFooterSuccessModal, setShowFooterSuccessModal] = useState(false);
    const [showCartDrawer, setShowCartDrawer] = useRecoilState(
        showCartDrawerAtom
    );

    const Menu = loadable(() => import("../components/menu.jsx"));

    useEffect(() => {
        let local = localStorage.getItem("age");
        let session = sessionStorage.getItem("age");
        if (!local && !session)
            navigate("/agegate", {
                state: {
                    newPath: "/contenidos",
                },
            });
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty(
            "--vh",
            `${window.innerHeight / 100}px`
        );
    }, []);

    useEffect(() => {
        setIsMobile(IsMobileDevice());
    }, []);

    useEffect(() => {
        // const td = Cookies.get("_td");
        window?.dataLayer.push({
            brand: "Stella Artois",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Contenidos",
            pageType: "Basic page",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://www.stellaartois.com.ar/contenidos",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "",
        });
    }, []);

    return (
        <div className={styles.campaignsContainer}>
            <Seo title="Stella Artois" />
            {showNewsletterModal && (
                <BocadoModal
                    type="newsletter"
                    isMobile={isMobile}
                    setShowModal={setShowNewsletterModal}
                    title="BIENVENIDO A BOCADO CLUB"
                    subtitle="Registrate para poder participar de los premios y poder disfrutar de los beneficios. Enterate de las últimas novedades."
                />
            )}
            {showFooterSuccessModal && (
                <BocadoModal
                    isMobile={isMobile}
                    setShowModal={setShowFooterSuccessModal}
                    onlySuccess={true}
                />
            )}
            <BocadoCart
                showCartDrawer={showCartDrawer}
                setShowCartDrawer={setShowCartDrawer}
            />
            <Menu
                isMobile={isMobile}
                setShowNewsletterModal={setShowNewsletterModal}
                setShowCartDrawer={setShowCartDrawer}
            />
            <CampaignsContent
                // link={"#video"}
                cardsArray={data.allCampanas.nodes}
                isMobile={isMobile}
                mainComponent={true}
            />
            <Footer
                link={"/contenidos#inicio"}
                page={"novedades"}
                isMobile={isMobile}
                setShowModal={setShowFooterSuccessModal}
            />
        </div>
    );
};

export default Content;

export const data = graphql`
    query Content {
        allCampanas(
            sort: { fields: position, order: ASC }
            filter: { active: { eq: true } }
        ) {
            nodes {
                title
                video
            }
        }
    }
`;
